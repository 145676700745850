html,
body,
#root {
    height: 100%;
}
body {
    margin: 0;
    font-family:
        "Inter",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8f8;
    scrollbar-width: thin;
    scrollbar-color: #b6b6b6 #e1e1e1 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

*::-webkit-scrollbar-track {
    background: #e1e1e1 !important;
}

*::-webkit-scrollbar-thumb {
    background-color: #b6b6b6 !important;
    border-radius: 3px;
    border: 3px solid #b6b6b6 !important;
}

* {
    /** scrollbar fix for firefox */
    scrollbar-width: thin;
    scrollbar-color: #b6b6b6 #e1e1e1;
}
/* label {
    color: green !important;
    font-weight: 900 !important;
} */

.app-wrapper-image {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: fixed;
    left: 0;
    top: 0;
}

.app-wrapper-image:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #00000029, #dbdbdb42) !important;
    opacity: 1;
    z-index: 1;
}
/*.MuiContainer-root {*/
/*    padding-left: 0 !important;*/
/*    padding-right: 10px !important;*/
/*    padding-bottom: 20px;*/
/*}*/

.react-toast-notifications__container {
    z-index: 100000 !important;
}
.MuiButton-root {
    text-transform: none !important;
}

.logo-img {
    height: 77px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

/* button.MuiButton-contained.Mui-disabled { */
/*background-color: rgb(129 89 70) !important;*/
/*border: 1px solid rgb(129 89 70) !important;*/
/*color: white !important;*/
/*cursor: not-allowed !important;*/
/* } */
.ag-header-row {
    background-color: #eaf6f7 !important;
}
.grid-download-btn {
    border: 1px solid #2d7abe;
    border-radius: 5px;
    color: #2d7abe;
    background-color: #fff;
    height: 24px;
    cursor: pointer;
}

.custom-warning-color {
    color: #8f4e00 !important;
}

/* .MuiIconButton-root.Mui-disabled { */
/*color: rgb(142 46 1) !important;*/
/* } */

.MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled):not(.Mui-error) {
    color: #999999;
}

@media (max-width: 1200px) {
    .logo-img {
        height: 75px;
    }
}

@media (min-height: 800px) and (max-height: 850px) and (min-width: 1201px) {
    .logo-img {
        height: 95px;
    }
}

@media (min-height: 900px) and (min-width: 1201px) {
    .logo-img {
        height: 125px;
    }
}

@media (max-width: 768px) {
    .app-wrapper-image {
        background-attachment: fixed;
        background-position: inherit;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
        position: fixed;
    }
    .MuiButton-startIcon .MuiButton-iconSizeMedium {
        width: 15px;
    }
}
@media (min-width: 500px) and (max-width: 1378px) {
    .app-container {
        max-width: 90% !important;
        font-size: smaller;
    }
}
@media (max-width: 350px) {
    .icon-button-mobile {
        width: 30px;
    }
}

.no-builds-message {
    z-index: 1000;
    position: relative;
    text-align: center;
}
@page {
    size: 5in 3in;
    margin: 0in;
}

@-moz-document url-prefix() {
    .MuiPhoneNumber-flagButton svg {
        width: 30px !important;
    }
}
.la-ball-beat,
.la-ball-beat > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.la-ball-beat {
    display: block;
    font-size: 0;
    color: #fff;
    width: 54px;
    height: 18px;
}
.la-ball-beat.la-dark {
    color: #333;
}
.la-ball-beat > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    -webkit-animation: ball-beat 0.7s -0.15s infinite linear;
    -moz-animation: ball-beat 0.7s -0.15s infinite linear;
    -o-animation: ball-beat 0.7s -0.15s infinite linear;
    animation: ball-beat 0.7s -0.15s infinite linear;
}
.la-ball-beat > div:nth-child(2n-1) {
    -webkit-animation-delay: -0.5s;
    -moz-animation-delay: -0.5s;
    -o-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.la-ball-beat.la-sm {
    width: 26px;
    height: 8px;
}
.la-ball-beat.la-sm > div {
    width: 4px;
    height: 4px;
    margin: 2px;
}
.la-ball-beat.la-2x {
    width: 108px;
    height: 36px;
}
.la-ball-beat.la-2x > div {
    width: 20px;
    height: 20px;
    margin: 8px;
}
.la-ball-beat.la-3x {
    width: 162px;
    height: 54px;
}
.la-ball-beat.la-3x > div {
    width: 30px;
    height: 30px;
    margin: 12px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-beat {
    50% {
        opacity: 0.2;
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-moz-keyframes ball-beat {
    50% {
        opacity: 0.2;
        -moz-transform: scale(0.75);
        transform: scale(0.75);
    }
    100% {
        opacity: 1;
        -moz-transform: scale(1);
        transform: scale(1);
    }
}
@-o-keyframes ball-beat {
    50% {
        opacity: 0.2;
        -o-transform: scale(0.75);
        transform: scale(0.75);
    }
    100% {
        opacity: 1;
        -o-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes ball-beat {
    50% {
        opacity: 0.2;
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        -o-transform: scale(0.75);
        transform: scale(0.75);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
.krmNah {
    /*display: none !important;*/
    background-color: #ffffff !important;
    box-shadow: 0 0 1px 3px #2d7abe !important;
}

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px #fafafa;
}
.MuiButtonBase-root:disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.pac-container {
    z-index: 11000 !important;
    position: absolute;
    padding-bottom: 12px;
    margin-right: 12px;
}
