.showActionSpinner {
    background: rgba(59, 59, 59, 0.76);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100000;
    padding: 1.2rem;
    justify-content: center;

}
.hideActionSpinner {
    display: none;
}
