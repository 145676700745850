.home-btn {
    border-radius: 20px !important;
    padding: 5px 25px !important;
    margin-right: 5px !important;
    color: white;
}

.home-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
}

@media (max-width: 500px) {
    .text-mobile {
        font-size: 70px !important;
    }
    .text-mobile-text {
        font-size: 25px !important;
    }
}
